
import banner7 from "../../assets/25.png"
import banner8 from "../../assets/30.png"
import banner9 from "../../assets/24.png"
import banner10 from "../../assets/31.png"

import banner12 from "../../assets/26.png"
import banner13 from"../../assets/27.png"
import banner14 from "../../assets/28.png"
import banner15 from "../../assets/29.png"









const portfolioData = [
    {
        image:banner7,
        path:""
    },
    {
        image:banner9,
        path:""
    },
    {
        image:banner8,
        path:""
    },
    {
        image:banner10,
        path:""
    },

    {
        image:banner12,
        path:""
    },
    {
        image:banner13,
        path:""
    },
    {
        image:banner14,
        path:""
    },
    {
        image:banner15,
        path:""
    },
]

export default portfolioData