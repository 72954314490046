
import banner7 from "../../assets/banner-7.png"
import banner8 from "../../assets/banner-8.png"
import banner9 from "../../assets/banner-9.png"
import banner10 from "../../assets/banner-10.png"
import banner11 from "../../assets/banner-11.png"
import banner12 from "../../assets/banner-12.png"
import banner13 from "../../assets/banner-13.png"
import banner14 from "../../assets/banner-14.png"
import banner15 from "../../assets/banner-15.png"









export const mainData = [
    {
        image:banner7,
        path:""
    },
    {
        image:banner9,
        path:""
    },
    {
        image:banner8,
        path:""
    },
    {
        image:banner10,
        path:""
    },
    {
        image:banner11,
        path:""
    },
    {
        image:banner12,
        path:""
    },
    {
        image:banner13,
        path:""
    },
    {
        image:banner14,
        path:""
    },
    {
        image:banner15,
        path:""
    },
]