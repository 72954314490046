import Section_1 from '../components/Career/Title'
import Section_2 from '../components/Career/WorkWithUs'
import Section_3 from '../components/Career/ApplicationForm'

export default function Career(){

    return(
        <div className=" bg-primary_bright">
            <Section_1/>
            <Section_2/>
            <Section_3/>
        </div>
    )
}